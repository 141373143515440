/* General Styles */

.page {
  min-height: 100%;
  background: linear-gradient(181.35deg, #740c0c 0.3%, #53bc00 99.71%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: url("./images/uwu.png"), auto;
  overflow-x: hidden;
}

.text {
  font-size: 80px;
  z-index: 1;
  line-height: 90px;
}

/* Uncut Funk / First page */

.impeterwu {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 80px;
  top: 152px;
}

.discordUwu {
  position: absolute;
  top: 0;
  right: 0;
}

.uwuContainer {
  position: relative;
}

.uwu {
  position: absolute;
  color: #d829d1;
}

.uncutFunk {
  margin: 60px auto;
}

.blinkingUwu {
  position: absolute;
  width: 260px;
  animation: blinkingText 2s alternate infinite;
  font-size: 120px;
}
@keyframes blinkingText {
  0% {
    color: #2ba431;
    transform: translate3d(0, 25px, 0);
  }
  25% {
    color: #d829d1;
    transform: translate3d(0, -25px, 0);
  }
  50% {
    color: #2ba431;
    transform: rotate(180deg);
  }
  75% {
    color: #d829d1;
    transform: rotate(360deg);
  }
  100% {
    color: #2ba431;
    transform: scale(1.4);
  }
}

/* R/Berkeley power */

.power {
  width: 1280px;
  max-width: 100%;
  height: 850px;
}

.powerTextContainer {
  margin-left: 80px;
  margin-top: 49px;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: left;
  width: 1122px;
  height: 747px;
  border: 31px solid #000;
}

.powerBoxes {
  border: 31px solid #000;
  position: absolute;
}

.powerBox1 {
  composes: powerBoxes;
  top: -31px;
  left: -31px;
  width: 788px;
  height: 524px;
}

.powerBox2 {
  composes: powerBox1;
  width: 881px;
  height: 586px;
}
.powerBox3 {
  composes: powerBox1;
  width: 1005px;
  height: 669px;
}

.hasText {
  color: #740c0c;
}

.powerText {
  color: #e80303;
  text-transform: capitalize;
  font-weight: bold;
  margin-left: -500px;
  width: 10000px;
}

.rberkeley {
  font-size: 40px;
  color: #bba88a;
}

.powerImg {
  z-index: 1;
}

.sat {
  position: relative;
  width: 1440px;
  height: 650px;
  color: #fdfc53;
  font-size: 40px;
}

/* SAT region */
.satLogo {
  position: absolute;
  left: 0px;
}

.fullScore {
  font-size: 400px;
  position: absolute;
  right: 0px;
  top: -200px;
}

.achieve {
  color: #000;
  position: absolute;
  right: 300px;
  top: 100px;
  font-weight: bold;
}

.math {
  position: absolute;
  left: 500px;
  top: 300px;
}

.reading {
  position: absolute;
  left: 721px;
  top: 327px;
}

.writing {
  position: absolute;
  left: 999px;
  top: 279px;
}

.math800 {
  position: absolute;
  left: 535px;
  top: 350px;
}

.reading800 {
  position: absolute;
  left: 741px;
  top: 450px;
}

.writing800 {
  position: absolute;
  left: 979px;
  top: 309px;
}

.gre {
  position: absolute;
  top: 460px;
  color: #000;
  font-size: 80px;
}

.gregre {
  font-size: 140px;
  color: #ffef00;
}

/* MAGIC */

.mtg {
  position: relative;
  width: 1340px;
  margin-bottom: 30px;
}

/* STONKS */
.stonks {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.player {
  position: relative;
}

.unpausable {
  position: absolute;
  width: 650px;
  height: 367px;
  top: 0;
  left: 0;
}

.stonkImage {
  margin-left: 80px;
}

.indie {
  position: absolute;
  z-index: 1;
  color: #ffef00;
  font-size: 50px;
  top: 180px;
  left: -60px;
}

/* Starterpack */

.starterPack {
  width: 1200px;
  align-self: start;
  margin-left: 60px;
}

.stars {
  background: url("./images/star.png") repeat;
  background-size: contain;
  display: block;
  height: 40px;
}

.starterPackText {
  color: #ffd600;
}

.starterPackItems {
  height: 600px;
  width: 100%;
}

.footer {
  align-self: flex-end;
  margin-right: 30px;
  color: #fdfc53;
}

.footer a {
  text-decoration: underline;
}
